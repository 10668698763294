.App {
  text-align: center;
  background: #000;

}

.App-logo {
  height: 15vmin;
  pointer-events: none;
  
}

.AppBg {
  background-image: url("./background.png");
  /* Add the blur effect */
  filter: blur(10px);
  -webkit-filter: blur(10px);
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-size: cover;
  position: absolute;
}
.App-header {
  position: relative;
  /* background-color: #282c34; */
  z-index: 2;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
